@font-face {
  font-family: 'Inter';
  src: url('Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter Black';
  src: url('Inter-Black.ttf');
}

@font-face {
  font-family: 'Inter Bold';
  src: url('Inter-Bold.ttf');
}

@font-face {
  font-family: 'Inter ExtraBold';
  src: url('Inter-ExtraBold.ttf');
}

@font-face {
  font-family: 'Inter ExtraLight';
  src: url('Inter-ExtraLight.ttf');
}

@font-face {
  font-family: 'Inter Light';
  src: url('Inter-Light.ttf');
}

@font-face {
  font-family: 'Inter Medium';
  src: url('Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter SemiBold';
  src: url('Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'Inter Thin';
  src: url('Inter-Thin.ttf');
}
