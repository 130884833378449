@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* STATES */
  .checked {
    @apply peer-active:border-interactive-backgroundStrongActive;
  }
  .unchecked {
    @apply border-2 border-solid border-neutral-textHint
    hover:border-neutral-textStrong peer-active:border-neutral-textStrong;
  }

  .checked.radio {
    @apply border-6 border-solid border-interactive-backgroundStrong bg-base-white
    hover:border-interactive-backgroundStrongHover;
  }

  .checked.checkbox {
    @apply bg-interactive-backgroundStrong flex items-center justify-center hover:bg-interactive-backgroundStrongHover;
  }

  .checked.checkbox.disabled {
    @apply bg-neutral-textDisabled;
  }

  .checked.disabled,
  .unchecked.disabled {
    @apply border-neutral-textDisabled 
    peer-disabled:hover:border-neutral-textDisabled peer-disabled:active:border-neutral-textDisabled
    peer-disabled:focus:outline-none;
  }

  /* TYPES */
  .radio {
    @apply rounded-full;
  }
  .checkbox {
    @apply rounded-md;
  }

  .radio,
  .checkbox {
    @apply peer-focus:outline peer-focus:outline-offset-2 
    peer-focus:outline-interactive-text;
  }

  /* CONTAINER */
  .inputContainer {
    @apply border border-solid rounded-lg relative;
  }
  .inputContainer.checked {
    @apply border-interactive-border hover:border-interactive-borderHover active:border-interactive-borderHover;
  }
  .inputContainer.unchecked {
    @apply border-neutral-border hover:border-neutral-borderHover;
  }
  .inputContainer.disabled {
    @apply border-neutral-border hover:border-neutral-border;
  }
  .inputContainer.unchecked.mouseDown {
    @apply !border-interactive-text;
  }
  .inputContainer.checked.mouseDown {
    @apply !border-interactive-backgroundStrongActive;
  }
  .inputContainer.focused {
    @apply outline-2 outline outline-offset-1 outline-interactive-text;
  }
}
