@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .ToolAnchor {
    @apply transition-all duration-200 cursor-pointer flex px-spacing-18 w-full rounded-lg 
          hover:bg-neutral-fieldHover active:bg-neutral-fieldActive;
  }
}
