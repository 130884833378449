@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tooltip {
    @apply relative rounded-4sm;
  }
  .tooltipContent {
    @apply transition-all flex justify-center opacity-0 peer-hover:opacity-100 duration-300 absolute 
    bg-base-platinum700 p-spacing-10 rounded-4sm top-full mt-spacing-20 z-0 peer-hover:z-10 text-base-white;
  }
  .tooltip-text {
    @apply text-base-white;
    font-family: 'Inter';
  }
  .arrow-up {
    @apply absolute -top-spacing-12 w-0 h-0 border-solid border-x-transparent border-b-base-platinum700;
    border-left-width: 12px;
    border-right-width: 12px;
    border-bottom-width: 12px;
  }
}
