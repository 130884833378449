@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* VARIANTS */
  .button.primary {
    @apply border-0 bg-interactive-backgroundStrong text-base-white hover:bg-interactive-backgroundStrongHover 
    focus:outline-2 focus:outline-offset-1 focus:outline-interactive-borderActive focus:outline active:bg-interactive-backgroundStrongActive;
  }
  .button.primary.disabled {
    @apply bg-base-gray100 text-base-gray300;
  }
  .button.secondary {
    @apply bg-interactive-field text-interactive-text hover:text-interactive-textHover hover:bg-interactive-fieldHover
    focus:outline-2 focus:outline-offset-2 focus:outline-interactive-borderActive focus:outline active:bg-interactive-fieldActive;
  }
  .button.secondary.disabled {
    @apply bg-neutral-backgroundWeak text-neutral-textDisabled;
  }
  .button.tertiary {
    @apply border-0 bg-transparent text-interactive-text hover:bg-interactive-field
    focus:outline-2 focus:outline-offset-1 focus:outline-interactive-borderActive focus:outline active:bg-interactive-fieldActive;
  }
  .button.tertiary.disabled {
    @apply text-neutral-textDisabled bg-transparent;
  }

  /* SIZES */
  .button.default {
    @apply px-spacing-16 py-spacing-8;
  }
  .button.medium {
    @apply px-spacing-24 py-spacing-10;
  }
  .button.large {
    @apply px-spacing-28 py-spacing-14;
  }
  .button.xlarge {
    @apply px-spacing-40 py-spacing-18;
  }

  /* icons BUTTON POSITION */
  .iconLeft {
    @apply flex-row;
  }
  .iconLeft svg {
    @apply mr-spacing-8;
  }
  .iconRight {
    @apply flex-row-reverse;
  }
  .iconRight svg {
    @apply ml-spacing-8;
  }
}
